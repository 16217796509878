@use "../../utils/shared";

$ROOT: shared.makeRoot("UserAvatar");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply fixed right-2 top-2;
}
