@use "../../utils/shared";

$ROOT: shared.makeRoot("ThemeChanger");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply fixed bottom-0 right-0 p-4;
  @apply opacity-30 hover:opacity-100;
}
