@use "../../utils/shared";

$ROOT: shared.makeRoot("PlayerPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply mt-4 flex flex-col space-y-3 min-h-screen;
}

#{el('highlight')} {
  @apply font-semibold;
}

#{el("information-table")} {
  @apply flex flex-row justify-evenly w-full mx-auto px-6 py-4;

  p {
    @apply text-sm;
  }
}

#{el('divider')} {
  @apply py-2;
}

#{el('infobox')} {
  @apply flex space-x-4;
}

#{el('subinformation-table')} {
  @apply flex flex-col space-y-4;
}

#{el('iconLabel')} {
  @apply flex flex-row items-center space-x-3;
  svg {
    @apply text-brand-500;
  }
}

#{el('realServerSpan')} {
  @apply flex space-x-3 items-center;
}

#{el('profile')} {
  @apply flex flex-row p-3;
}

#{el('profileInfo')} {
  @apply flex flex-col p-5 space-y-3;
}

#{el('profileInfoPanel')} {
  @apply flex;
}

#{el('actionButtons')} {
  @apply flex flex-row space-x-5;
}
