@use "../../utils/shared";

$ROOT: shared.makeRoot("Layout");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{el('container')} {
  @apply flex;
}
