@use "../../utils/shared";
@use "../../utils/variables" as var;

$ROOT: shared.makeRoot("StatCard");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply flex flex-col text-center w-full h-24 justify-center rounded-md shadow-md space-y-1;
  @apply transition-all duration-300 ease-in-out;
}

#{el('title')} {
  @apply text-lg;
}

#{el('value')} {
  @apply text-lg font-bold;
}

#{el('header')} {
  @apply flex items-center justify-center space-x-2;
}

#{el('icon')} {
  @apply text-brand-500;
}
