@use "../../utils/shared";

$ROOT: shared.makeRoot("SimpleModal");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{el('footer')} {
  @apply flex space-x-5;
}

// #{$ROOT} {
// }

// #{el('example-element')} {
//   @apply absolute right-[20px] top-[20px];
// }
