@use "../../utils/shared";

$ROOT: shared.makeRoot("DashboardPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply flex-col justify-center mt-12;
}

#{el("header-cards")} {
  @apply space-y-3;
}

#{el("stats")} {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center;
  @apply pb-3 px-4;
}

#{el('activity-stats')} {
  @apply flex flex-row justify-evenly;
}
