@use "../../utils/shared";

$ROOT: shared.makeRoot("DataChart");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply space-y-3;
}

#{el('label')} {
  @apply font-bold text-lg text-center;
}
