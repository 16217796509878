@use "../../utils/shared";

$ROOT: shared.makeRoot("Copyright");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply text-center text-sm my-auto mx-auto justify-center w-fit flex flex-col space-y-2;
  padding: 0 18px;

  &.is-absolute {
    transform: translate(-50%, 0);
    @apply absolute left-1/2 bottom-4;
  }
}

#{el('version')} {
  @apply text-xs;
}

[style="light"] {
  #{$ROOT} {
    @apply text-gray-400;
  }
}

[style="dark"] {
  #{$ROOT} {
    @apply text-gray-600;
  }
}
