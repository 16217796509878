@use "../../utils/shared";

$ROOT: shared.makeRoot("HiddenText");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{el('button')} {
  @apply w-full rounded font-semibold text-base my-auto px-2;
}

[style="light"] {
  #{el('button')} {
    @apply text-brand-500 bg-transparent;
    &.hidden {
      @apply text-transparent;
      @apply bg-true-gray-500;
    }
  }
}

[style="dark"] {
  #{el('button')} {
    @apply text-brand-500 bg-transparent;
    &.hidden {
      @apply text-transparent;
      @apply bg-true-gray-700;
    }
  }
}
