@use "../../utils/shared";

$ROOT: shared.makeRoot("TokenExpiredPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply absolute m-auto inset-0 flex flex-col justify-center items-center text-center space-y-10 w-fit h-fit;

  h1 {
    @apply text-2xl font-bold;
  }
}

#{el('button-text')} {
  @apply mt-1;
}

#{el('iconLabel')} {
  @apply flex items-center space-x-5;
}
