@use "../../utils/shared";
@use "../../utils/variables" as var;

$ROOT: shared.makeRoot("NoAccessPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply m-auto flex flex-col justify-center items-center text-center min-h-screen;

  h1 {
    @apply text-lg font-bold;
  }
}

#{el('container')} {
  @apply flex-col space-y-5 justify-center items-center m-auto;
}
