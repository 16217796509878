@use "../../utils/shared";

$ROOT: shared.makeRoot("RCONConsole");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply w-full h-full flex-1 flex flex-col space-y-3 justify-between;
}

#{el('rcon-console')} {
  @apply w-full rounded inline-block overflow-y-auto px-4 py-2 h-96;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    @apply bg-true-gray-800;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded w-1 mx-auto bg-brand-500;
  }

  overflow-y: scroll;
  scrollbar-width: thin;
}

#{el('rcon-command')} {
  @apply flex flex-row space-x-2;
}

#{el('rcon-command-input')} {
  @apply text-brand-500;
  text-shadow: rgba(215, 68, 83, 0.8) 0 0 10px;
}

#{el('rcon-command-output')} {
  @apply text-true-gray-300;
}

#{el('rcon-command-error')} {
  @apply text-true-gray-500;
}

[style="dark"] {
  #{el('rcon-console')} {
    background-color: rgb(30, 30, 30);
  }
}

[style="light"] {
  #{el('rcon-console')} {
    background-color: rgb(250, 250, 250);
    @apply shadow-lg;
  }
}
