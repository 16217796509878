@use "../../utils/shared";

$ROOT: shared.makeRoot("PlayerBansPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply mt-4 flex flex-col space-y-3;
}
