@use "../../utils/shared";
@use "../../utils/variables" as var;

$ROOT: shared.makeRoot("Sidebar");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply inline-block ml-4 mt-4;
  @apply transition-all duration-300 ease-in-out;
}

#{el('collapsed')} {
  @apply w-64;
}

#{el('menu')} {
  @apply rounded-md;
  min-height: calc(100vh - 2rem);
}

[style="dark"] {
  #{el('menu')} {
    background-color: rgb(30, 30, 30);
  }
}

[style="light"] {
  #{el('menu')} {
    background-color: rgb(250, 250, 250);
    @apply shadow-lg;
  }
}
