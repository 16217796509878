@use "../../utils/shared";

$ROOT: shared.makeRoot("RconPage");
@function el($name) {
  @return shared.makeSubElementName($ROOT, $name);
}

#{$ROOT} {
  @apply my-auto justify-center w-full h-fit flex min-h-screen;
}

#{el('login-form')} {
  @apply space-y-5 text-center w-[250px] h-fit mx-auto flex flex-col my-auto;
}

#{el('login-inputs')} {
  @apply flex flex-col m-auto space-y-5 w-full h-fit;
}

#{el('console-section')} {
  @apply mt-44 w-full h-1/2;
}

#{el('input-submit')} {
  @apply w-full;
}

#{el('center-me')}
{
  @apply my-auto flex space-x-10;
}

#{el('divider')}
{
  @apply w-0.5 bg-brand-500 rounded-lg;
}

#{el('send-to-all')}
{
  @apply flex-col space-y-5;
}
